.container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .navbar {
    box-shadow: 0px 5px 10px 0px #aaa;
    height: 80px;
    width: 100%;
    top: 0;
    background-color: #ffffff;
    position: fixed;
    z-index: 1;
  }
  
  .navbar .hamburger-lines{
      display: none;
  }
  
  
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  
  .nav-elements ul li:not(:last-child) {
    margin-right: 60px;
  }
  
  .nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
  }
  
  .nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
  }
  
  .nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
  }

  button {
    cursor: pointer;
    color: #54db00;
    border: 1px solid #6cbb3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #ffffff;
    width: fit-content;
}
  
  @media (max-width: 768px){
       .container{
          display: block;
          position: relative;
          height: 64px;
      } 
  
      /* nav bar screen responsive animation has to be better  */
  
      .container .hideNavBar { 
          transform: scale(0);
          transition: transform 0.3s step-end;
      }
  
      .container .hamburger-lines{
        height: 28px;
        width: 35px;
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }
  
      .container .hamburger-lines .line{
          display: block;
          height: 4px;
          width: 100%;
          border-radius: 10px;
          background: #333;
      }
      
      .container .hamburger-lines .line1{
          transform-origin: 0% 0%;
          transition: transform 0.3s ease-in-out;
      }
  
      .container .hamburger-lines .line2{
          transition: transform 0.2s ease-in-out;
      }
  
      .container .hamburger-lines .line3{
          transform-origin: 0% 100%;
          transition: transform 0.3s ease-in-out;
      }
  
      .navbar .nav-items{
          padding: 20px;
          background: #ffffff;
          height: 80vh;
          max-width: 200px;
          transform: translate(-150%);
          display: flex;
          flex-direction: column;
          margin-left: -40px;
          padding-left: 40px;
          transition: transform 0.5s ease-in-out;
          box-shadow:  5px 0px 10px 0px #aaa;
          overflow: scroll;
          z-index: 12;
      }
  
      .showNavBar .nav-items {
        transform: translateX(0);
      }
    
      .hideNavBar .nav-items {
        transform: translate(-150%);
      }
    
      .showNavBar .line1 {
        transform: rotate(45deg);
      }
    
      .showNavBar .line2 {
        transform: scaleY(0);
      }
    
      .showNavBar .line3 {
        transform: rotate(-45deg);
      }
  }
  
  @media (max-width: 500px){
      .container input[type="checkbox"]:checked ~ .logo{
          display: none;
      }
  }