.orders {
    position: relative;
    margin-top: 10%;
    margin-bottom: 10%;
    
}

.orders a {
    font-size: 16px;
    font-weight: 400;
    color: #6cbb3c;
    text-decoration: none;
    cursor: pointer;
}

.orders a:hover {
    color: #038f18;
    font-weight: 500;
  }
  
.orders .active {
color: #449213;
text-decoration: underline;
font-weight: 600;
}