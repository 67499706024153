/* Base styles for the about container */
.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  /* Style for headings */
  .about-container h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .about-container h2 {
    font-size: 1.8em;
    color: #555;
    margin-bottom: 15px;
  }
  
  /* Style for paragraphs and lists */
  .about-container p {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px;
  }
  
  .about-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .about-container li {
    margin-bottom: 10px;
  }
  
  .about-container strong {
    color: #333;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .about-container {
      padding: 15px;
    }
  
    .about-container h1 {
      font-size: 1.8em;
    }
  
    .about-container h2 {
      font-size: 1.5em;
    }
  
    .about-container p,
    .about-container li {
      font-size: 0.9em;
    }
  }
  