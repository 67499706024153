.addOrderForm {
  padding: 30px;
  background-color: #f4f6f9;
  border-radius: 12px;
  margin: 20px 0;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.addOrderForm h3 {
  margin-bottom: 20px;
  font-size: 1.8em;
  text-align: center;
  color: #2d3748;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.formGroup {
  margin-bottom: 20px;
}

.addOrderForm label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 1.1em;
  color: #4a5568;
  font-family: "Poppins", sans-serif;
}

.addOrderForm input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.addOrderForm input:focus {
  border-color: #6cbb3c;
  box-shadow: 0 4px 8px rgba(108, 187, 60, 0.3);
}

.orderItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.orderItem input {
  width: 30%;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.removeBtn {
  background-color: transparent;
  border: none;
  color: #e53e3e;
  cursor: pointer;
  font-size: 1.2em;
  transition: color 0.2s ease;
}

.removeBtn:hover {
  color: #c53030;
}

.addItemBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: #6cbb3c;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(108, 187, 60, 0.2);
}

.addItemBtn:hover {
  background-color: #58a02d;
}

.submitBtn {
  width: 100%;
  padding: 15px;
  background-color: #6cbb3c;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2em;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(108, 187, 60, 0.3);
}

.submitBtn:disabled {
  background-color: #cbd5e0;
  cursor: not-allowed;
  box-shadow: none;
}

.submitBtn:hover:enabled {
  background-color: #58a02d;
  box-shadow: 0 8px 16px rgba(88, 160, 45, 0.2);
}

.orderSummary p {
  margin: 5px 0;
  font-size: 1.1em;
  color: #2d3748;
  font-family: "Poppins", sans-serif;
}

.addOrderForm select {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1em;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.addOrderForm select:focus {
  border-color: #6cbb3c;
  box-shadow: 0 4px 8px rgba(108, 187, 60, 0.3);
}

/* Improved Dropdown */
.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  width: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  font-family: "Poppins", sans-serif;
}

.dropdown li {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1em;
  color: #2d3748;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-bottom: 1px solid #e2e8f0;
}

.dropdown li:last-child {
  border-bottom: none;
}

.dropdown li:hover {
  background-color: #f7fafc;
  color: #6cbb3c;
}

/* Custom scrollbar for dropdown */
.dropdown::-webkit-scrollbar {
  width: 6px;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 10px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #a0aec0;
}


/* Mobile responsiveness */
@media (max-width: 768px) {
  .addOrderForm {
      padding: 15px;
  }

  .orderItem {
      flex-direction: column;
      align-items: flex-start;
  }

  .orderItem input {
      width: 100%;
      margin-bottom: 10px;
  }

  .dropdown {
    width: 90%;
  }

  .submitBtn {
      font-size: 1em;
  }
}

@media (max-width: 480px) {
  .addOrderForm {
      padding: 10px;
  }

  .addOrderForm h3 {
      font-size: 1.4em;
  }

  .addOrderForm input {
      padding: 10px;
      font-size: 0.9em;
  }

  .submitBtn {
      padding: 12px;
      font-size: 1em;
  }
}
