.menuCategories {
  position: relative;
  padding-top: 10%;
  padding-bottom: 10%;
}

.menuCategories a {
  font-size: 16px;
  font-weight: 400;
  color: #6cbb3c;
  text-decoration: none;
}

.menuCategories a:hover {
  color: #038f18;
  font-weight: 500;
}

.menuCategories .active {
  color: #449213;
  text-decoration: underline !important;
  font-weight: 600;
}

.addCategoryButton {
  cursor: pointer;
  color: #000000;
  border: 1px solid #6cbb3c;  
  font-weight: 700;
  border-width: 1px;
  border-radius: 0.2vw;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0.5); 
  padding-bottom: 0.5rem;
}

.addCategoryButton:active {
  color: #e6e6e6;
  background-color: #6cbb3c;
}

.actionButtons {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.deleteButton {
  color: #ff4d4d;
  border-color: #ff4d4d;
}

.deleteButton:active {
  color: #e6e6e6;
  background-color: #6cbb3c;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 18px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6cbb3c;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.hidden {
  opacity: 0.5;
  filter: blur(3px);
}
