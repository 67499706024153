.menuItemContainer {
    position: relative;
}

.menuItem {
    margin: 20px;
    padding: 20px;
    margin-top: 10%;
    margin-bottom: 10%;
    border-bottom:1px solid #101011;
    transition: opacity 0.3s ease;
}

.hidden {
    opacity: 0.5;
    filter: blur(3px);
}

.priceContainer {
    display: inline-flex;
}


@media screen and (min-width: 1024px) {     /*  inline-flex only on bigger screens */
    .menuItem {
        display:inline-flex;
    }
    
}

.menuImage {
    height: 200px;
    object-fit: contain;
}

@media screen and (max-width: 1024px) { 
    .menuImage {
        height: 100px;
        object-fit: contain;
    }
}

.menuItemDescription {
    margin-left: 20px;
}

 /* add item button */
 .addItemButton {
    cursor: pointer;
    color: #000000;
    border: 1px solid #6cbb3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(255, 255, 255, 0.5); 
    padding-bottom: 0.5rem;
}

.addItemButton:active, .deleteButton:active {
    color: #e6e6e6;
    background-color: #6cbb3c;
}

.actionButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.deleteButton {
    color: #ff4d4d;
    border-color: #ff4d4d;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
    margin-left: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 18px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #6cbb3c;
}

input:checked + .slider:before {
    transform: translateX(16px);
}