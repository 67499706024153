.App {
  text-align: center;
}

.body-container {
  border: double medium #e5e3d6;
  border-top: 0;
  margin: 80px 30px 80px;
  padding: 0 0 30px;
  min-height: 80vh;
  overflow: hidden;
}

.signupLogin * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.signupLogin {
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
background: url('https://t4.ftcdn.net/jpg/02/78/69/37/360_F_278693728_O7t0fe2oDwwucHisBR1i8UhVxE5N229G.jpg') no-repeat; 
background-size: cover;
background-position: center;
}