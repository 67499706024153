.orderItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 70vw;
    max-height: 80%;
    overflow: auto;
}

.orderTable {
    border: 2px solid #606060; 
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
}

.orderStatusButtons {
    display: inline-flex;
}

.orderStatus p {
    margin: 10px;
    font-weight: 600; 
}

/*green button */
.greenButton {
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #6cbb3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: #54db00;
    padding-bottom: 0.5rem;
    width: fit-content;
    align-self: center;
}
  
.greenButton:active {
background-color: #44b001; 
}

/*red button */
.redButton {
    cursor: pointer;
    color: #ffffff;
    border: 1px solid #bb3c3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: #db0000;
    padding-bottom: 0.5rem;
    width: fit-content;
    align-self: center;
}
  
.redButton:active {
background-color: #b00101; 
}

/*bill button */
.billButton {
    cursor: pointer;
    color: #54db00;
    border: 1px solid #6cbb3c;  
    font-weight: 700;
    border-width: 1px;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 1rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: #ffffff;
    padding-bottom: 0.5rem;
    width: fit-content;
    align-self: center;
}
  
.billButton:active {
background-color: #44b001;
color: #ffffff; 
}

